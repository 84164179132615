import React, { useCallback, useEffect, useState } from "react";
import "./sidebar.scss";
import { Link, useLocation } from "react-router-dom";
import { images } from "../../constants";
import sidebarNav from "../../configs/sidebarNav";

const Sidebar = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const curPath = window.location.pathname.split("/").slice(-1)[0];
    const activeItem = sidebarNav.findIndex((item) => item.section === curPath);

    setActiveIndex(curPath.length === 0 ? 0 : activeItem);
  }, [location]);

  const closeSidebar = () => {
    document.querySelector(".main__content").style.transform =
      "scale(1) translateX(0)";
    setTimeout(() => {
      document.body.classList.remove("sidebar-open");
      document.querySelector(".main__content").style = "";
    }, 500);
  };

  const logout = useCallback(async () => {}, []);

  return (
    <div className='sidebar'>
      <div className='sidebar__logo'>
        <img src={images.logo} alt='' />
        <div className='sidebar-close' onClick={closeSidebar}>
          <i className='bx bx-x'></i>
        </div>
      </div>
      <div className='sidebar__menu'>
        {sidebarNav.map((nav, index) => (
          <Link
            to={nav.link}
            key={`nav-${index}`}
            className={`sidebar__menu__item ${
              activeIndex === index && "active"
            }`}
            onClick={closeSidebar}>
            <div className='sidebar__menu__item__icon'>{nav.icon}</div>
            <div className='sidebar__menu__item__txt'>{nav.text}</div>
          </Link>
        ))}
        <Link to='/logout' className='sidebar__menu__item'>
          <div className='sidebar__menu__item__icon'>
            <i className='bx bx-log-out'></i>
          </div>
          <div className='sidebar__menu__item__txt'>Logout</div>
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
